import BasePlugin from '../BasePlugin'
import ImportInvestmentBudgetExpensesToRequestCmp from './ImportInvestmentBudgetExpensesToRequestCmp'

export default class ImportInvestmentBudgetExpensesToRequest extends BasePlugin {
  async execute () {
    let requestId = this.context.getModel().id
    const h = this.context.$createElement
    this.context.$msgbox({
      title: 'Выберите файл для загрузки',
      message: h('p', { key: Math.random() }, [
        h(ImportInvestmentBudgetExpensesToRequestCmp, { props: { model: requestId, component: this.context.getDashboardComponents()['component_9671665d-6dd9-43b2-8e25-0bac61f6e094'][0] } })
      ]),
      showCancelButton: false,
      showConfirmButton: false
    })
  }
}
